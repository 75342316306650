import { DashboardOutlined, ShopOutlined } from "@ant-design/icons";
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH } from "configs/AppConfig";
import { RiDashboardLine } from "react-icons/ri";
import { FiUsers, FiSettings } from "react-icons/fi";
import { BsChatSquareText, BsTag } from "react-icons/bs";
import { MdOutlineDateRange } from "react-icons/md";
import { FaRegComments } from "react-icons/fa";
import { RiTeamLine } from "react-icons/ri";
import { TbFileInvoice } from "react-icons/tb";
import { AiOutlineShop } from "react-icons/ai";

const dashBoardNavTree = [
  {
    key: "dashboards",
    path: `${APP_PREFIX_PATH}/dashboards`,
    title: "sidenav.dashboard",
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "dashboards-default",
        path: `${APP_PREFIX_PATH}/dashboards`,
        title: "sidenav.dashboard",
        icon: "dashboard",
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "customer-list",
        path: `${APP_PREFIX_PATH}/customers/list`,
        title: "sidenav.Customers",
        icon: "customers",
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "mailing-list",
        path: `${APP_PREFIX_PATH}/mailing/list`,
        title: "sidenav.Mailings",
        icon: "mailings",
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "promotions-list",
        path: `${APP_PREFIX_PATH}/promotions/list`,
        title: "sidenav.Promotions",
        icon: "promotions",
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "campaigns-list",
        path: `${APP_PREFIX_PATH}/campaigns/list`,
        title: "sidenav.Campaigns",
        icon: "campaigns",
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "feedback-list",
        path: `${APP_PREFIX_PATH}/feedback/list`,
        title: "sidenav.Feedback",
        icon: "feedback",
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "my=stores-list",
        path: `${APP_PREFIX_PATH}/my-stores/list`,
        title: "sidenav.MyStores",
        icon: "mystores",
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "my-team-list",
        path: `${APP_PREFIX_PATH}/my-team/list`,
        title: "sidenav.MyTeam",
        icon: "myteam",
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "billings",
        path: `${APP_PREFIX_PATH}/billings`,
        title: "sidenav.Billings",
        icon: "billings",
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "settings",
        path: `${APP_PREFIX_PATH}/settings`,
        title: "sidenav.Settings",
        icon: "settings",
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
];

const navigationConfig = [...dashBoardNavTree];

export default navigationConfig;
